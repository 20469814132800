import React from 'react';
import Layout from '../../components/layout';
import ModalBox from '../../components/modals/modalBox';
//import './home.css';

export class Home extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    render() {
        /*
        return (
            <Layout aut={this.props.aut} _classes="home-layout">
                <ModalBox />
                <div className='Home'>
                    {
                    <img
                        className='Home-bckImage'
                        src={require('../../Assets/images/home2.png')}
                    />
                    }
                    <div className="wrap container-fluid">
                        <div className="row middle-xs">
                            <div className="col-xs-12 col-md-4">
                                <div className='Home-textContainer'>
                                    <div className='Home-text'>Bienvenid@</div>
                                    <div className='Home-description'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </div>
                                    <div className='home-btnContainer'>
                                        <a className='home-btn' href={'/courses-list'}>Empezar</a>
                                    </div>
                                </div>
                            </div>                                
                        </div>
                    </div>                        
                </div>
            </Layout>
        );*/
        return (
            <div className='Home'>
                {
                    /* <img
                         className='Home-bckImage'
                         src={require('../../Assets/images/home2.png')}
                     />*/
                }
                <div className="wrap container-fluid">
                    <div className="row middle-xs">
                        <div className="col-xs-12 col-md-4">
                            <div className='Home-textContainer'>
                                <div className='Home-text'>Bem-vindo!</div>
                                <div className='Home-description'>
                                    Seja bem-vindo à plataforma especialista na aprendizagem de português.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
