import { ME_URL } from "./urlManager";

export const userProfile = (token: string) => {
    const response = fetch(ME_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + token,
        },
        mode: 'cors'
    })
        .then(response => response.json()
        ).then(
            function (result) {
                console.log("RESPONSE", result);
                if (result.errors.length > 0) {
                    return false;
                }
                return result.body.userAccount.name;
            }
        ).catch(function (e) {
            console.log("ERROR", e);
        }
        )

    return response;
}