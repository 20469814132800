import { useContext, useEffect, useState } from 'react';
import { suscribeCourse } from '../../services/studentCourses';
import { Course } from '../../Utils/Types';
//import './curso-item.css';

type Props = {
    onButtonPressed: () => void,
    course: Course,
    token?: any,
    tipo:any,
}

const CursoItem = ({ onButtonPressed, course, token, tipo }: Props) => {
    //const { handleLogin, token, hostname, isLoged } = useContext(AdminContext);

    async function suscribeFreeCourse(courseId: any) {
        const response = await suscribeCourse(token, { course: courseId});
        console.log(response);

        if (response.success) {
            window.location.href = '/course?course=' + response.body.courseId;
        }
    }

    useEffect(() => {
       
    }, []);

    let mediaFile: any = null;
    if (course.course_data.media_file !== null)
        switch (course.course_data.media_type) {
            case 'image':
                mediaFile = <div className="image-question">
                    <img src={"" + course.course_data.media_file} />
                </div>
        }

    return (
        <div className="col-xs-12 col-sm-6 col-md-4">
            <div className='curso'>
                <div className='curso-header'>
                    {
                        course.course_data.kind_course_name !== 'De pago' ?
                            <div className='curso-headerFree'>
                                <div className='curso-headerFreeText'>curso grátis</div>
                            </div>
                            :
                            null
                    }
                    {
                        mediaFile !== null ?
                            mediaFile
                            :
                            <div className='course-item-image'>
                                <img
                                    className='curso-image'
                                    src={require('../../Assets/images/curso-small.jpg')}
                                />
                            </div>
                    }
                </div>
                <div className='curso-body'>
                    <div className='curso-title'>{course.course_data.title}</div>
                    <div className='curso-description'>{course.course_data.body}</div>
                </div>
                <div className='curso-footer'>
                    <div className='curso-nivel'>{course.course_data.level_class_name}</div>
                    {
                        tipo !== 'pago' ?
                            <a className='curso-btn btn' onClick={() => suscribeFreeCourse(course.course_data.id)} >Ir para o curso</a>
                            :
                            <a className='curso-btn btn' href={'/course?course=' + course.id} >Ir para o curso</a>
                    }
                </div>
            </div>
        </div>
    );
};


export default CursoItem;
