//import '../lection.css';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AnswerDraggable from './answerDraggable';

interface Props {
    category:string,
    categoryCardsHolder: any,
    catogryId:string,
}

const CategoryDroppable = (props: Props) => {

    return (
        <div className="category-container cat-style-only">
            <div className='lection-categoryDroppable'>{props.category}</div>
            <Droppable droppableId={props.catogryId}>
                {
                    (provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className='category-column'>
                            {
                                props.categoryCardsHolder?.map(
                                    (card:any, index:any) => (
                                        <AnswerDraggable key={"dropkeydrag" + index} answerText={card.phrase} answerId={card.id} index={index} />
                                    )
                                )
                            }
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        </div>
    );
}

export default CategoryDroppable;