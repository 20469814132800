//import '../lection.css';

import { useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import AnswerDraggable from "./answerDraggable";

interface Props {
    droppableId: any,
    options: any,
}

const PhraseOrderItem = (props: Props) => {

    return (
        <div className='lection-question'>
            {
                props.options.map(
                    (phrase:any, index:number) => (
                        <Droppable 
                            key={"drag-key-" + index} 
                            droppableId={index + 'droppable' + props.droppableId}
                            >
                            {
                                (provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            phrase.word?.map(
                                                (word:any, index:number) => (
                                                    <AnswerDraggable key={"drag-" + word} answerId={phrase.id + "-" + word} index={index} answerText={word} />
                                                )
                                            )
                                        }
                                        {provided.placeholder}
                                    </div>
                                )
                            }
                        </Droppable>
                    )
                )
            }
        </div>
    );
}

export default PhraseOrderItem;