import { REGISTER_URL } from "./urlManager";

export const register = async (user: any) => {
    console.log(user);
    const body_area = {
        name: user.name,
        last_name: user.lastName,
        email: user.email,
        phone: user.phone,
        password: user.password,
        password_repeat: user.repeatPassword
    }

    const response = await fetch(REGISTER_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body_area),
    }).then(response => response.json())
        .then(result => {
            return result;
        })
        .catch(error => console.log('error', error));

    if (!response.error) {
        //Cookies.set(AUTH_COOKIE, JSON.stringify(response));
    }

    return response;
}
