import { useEffect, useState } from 'react';
import IncompletePhrase from './QuestionComponents/incompletePhrase';
import MediaFile from './QuestionComponents/mediaFile';
import QuestionHeader from './QuestionComponents/question-header';
//import './lection.css';

interface Props {
    sentences: any,
    btnDisabled: boolean,
    onRespond: (value: any) => void,
    courseInfo: any,
    step: any,
    current: any
}

const CompleteSentenceExercise = (props: Props) => {
    const incompletePhrase: any = props.sentences;
    const [answerArray, setAnswerArray] = useState<any>();

    let phrases: any = new Array(incompletePhrase.length);

    for (let i: number = 0; i < phrases.length; i++) {
        let words = incompletePhrase[i].split(' ');
        let _words : any = [];
        for( let w of words ){
            if( w == '________________' )
                w = '';
            _words.push( w.trim() );
        }

        phrases[i] = {
            id: i,            
            words: _words
        }
    }
    //console.log(phrases);

    let focusedElement: any = '';

    function setActiveElement(value: any) {
        focusedElement = value;
    }

    function setSpecialCharacter(specialCaracter: any) {
        if (focusedElement !== undefined) {
            let array = '';
            if (focusedElement.id !== undefined) {
                focusedElement.value += specialCaracter.target.innerText;

                array = focusedElement.id.split('|');

                answerArray[array[1]].words[array[2]] = focusedElement.value;
            } else {
                focusedElement.value += specialCaracter.target.innerText;
            }
        } else {
            focusedElement.value += specialCaracter.target.innerText;
        }
    }

    function SetResponse() {
        let newAnswerArray = answerArray;
        let _newAnswerArray : any = [];
        

        for (let i: number = 0; i < newAnswerArray.length; i++) {
            _newAnswerArray[i] = newAnswerArray[i].words.map(function(el : any) {
                return el.trim();
            }).join(' ')
        }
        newAnswerArray = _newAnswerArray;
        
        /*
        for (let i: number = 0; i < newAnswerArray.length; i++) {
            newAnswerArray[i] = incompletePhrase[i];
            for (let f: number = 0; f < answerArray[i].words.length; f++) {
                newAnswerArray[i] = newAnswerArray[i].replace('________________', answerArray[i].words[f]);
            }
        }
        */
        //console.log(newAnswerArray);
        return newAnswerArray;
    }

    function reset() {
        console.log("RESETTING");

        let phrases: any = new Array(incompletePhrase.length);

        for (let i: number = 0; i < phrases.length; i++) {
            let words = incompletePhrase[i].split(' ');
            let _words : any = [];
            for( let w of words ){
                if( w == '________________' )
                    w = '';
                _words.push( w.trim() );
            }

            phrases[i] = {
                id: i,            
                words: _words
            }
        }
        /*
        phrases = new Array(incompletePhrase.length);
        for (let i: number = 0; i < phrases.length; i++) {
            phrases[i] = {
                id: i,
                words: new Array(incompletePhrase[i].split('________________').length - 1)
            }
            for (let f: number = 0; f < phrases.length; f++) {
                phrases[i].words[f] = '';
            }
        }
        */

        var inputs = document.getElementsByTagName('input');
        for (let i: number = 1; i < inputs.length; i++) {
            inputs[i].value = "";
        }
        setAnswerArray(phrases);
    }

    useEffect(() => {
        reset();
    }, [props]);

    return (
        <div className='lection'>
            <div className='lection-header'>
                <QuestionHeader current={props.current} courseInfo={props.courseInfo} question={props.step.question} lesson={props.step.lesson} totalQuestionNumber={props.courseInfo.course_data.lessons.length} />
                <MediaFile media_type={props.current.media_type} media_file={props.current.media_file} />
            </div>
            <div className='lection-body'>
                <div className='lection-columnContainer'>
                    {
                        incompletePhrase?.map(
                            (phrase: any, index: number) => (
                                <div key={index} className='lection-sentence'>
                                    {
                                        <div key={index} className="custom-indexer" style={{ marginBottom: "1rem" }}>
                                            <div className="circle-number">{index + 1}</div>
                                            <IncompletePhrase phrase={phrase} index={index} answersHolder={answerArray} focusedElement={setActiveElement} />
                                        </div>//
                                    }
                                </div>
                            )
                        )
                    }
                </div>
                <div className='special-chars-container'>
                    <div className='special-char' onClick={setSpecialCharacter}>à</div>
                    <div className='special-char' onClick={setSpecialCharacter}>ã</div>
                    <div className='special-char' onClick={setSpecialCharacter}>â</div>
                    <div className='special-char' onClick={setSpecialCharacter}>õ</div>
                    <div className='special-char' onClick={setSpecialCharacter}>ô</div>
                    <div className='special-char' onClick={setSpecialCharacter}>ç</div>
                    <div className='special-char' onClick={setSpecialCharacter}>ê</div>
                </div>
            </div>
            <div className='separator separator-foo' />
            <div className='lection-footer'>
                <img className='answerIconBtn' src={require('../../Assets/icons/answer.png')} onClick={() => {
                    props.onRespond(SetResponse);
                }} />
                <img className='redoIconBtn' src={require('../../Assets/icons/redo.png')} onClick={reset} />
            </div>
        </div>
    );
};
export default CompleteSentenceExercise;
