import { useContext, useEffect, useState } from 'react';
//import './lection.css';
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import AnswerDraggable from './QuestionComponents/answerDraggable';
import ImageDroppabble from './QuestionComponents/imageDropabble';
import { nextQuestion } from '../../services/studentCourses';
import QuestionHeader from './QuestionComponents/question-header';
import { Category } from '../../Utils/Types';
import CategoryDroppable from './QuestionComponents/categoryDroppable';
import VideoDroppabble from './QuestionComponents/VideoDroppable';
import MediaFile from './QuestionComponents/mediaFile';

interface Props {
    type: any,
    phrases: any[],
    droppableContent: any[],
    onRespond: (value: any) => void,
    course: any,
    aut: any,
    step: any,
    courseInfo: any,
    current: any
}

const DragNDropMultiple = (props: Props) => {
    //const { handleLogin, token, hostname, isLoged } = useContext(AdminContext);
    const [phrases, setPhrases] = useState(props.phrases);
    const [content, setContent] = useState<any>(props.droppableContent);

    let [contentHolder, setContentHolder] = useState<any>();
    let [phrasesHolder, setPhrasesHolder] = useState<any>();

    let sentencesStrings = '';

    console.log("LOG P&C",phrases, content);
    // copy of phrases || aqui guardo en formato string las frases, no se me ocurrio como guardarlo de otra manera
    phrases.map(
        (card: any) => {
            sentencesStrings += card.phrase ? card.phrase + "|" : card + "|";
        }
    );

    // set draggables, with unique id
    let phrasesCards: any = new Array(sentencesStrings.split('|').length);
    //console.log( "DEBUH PHRASES CARDS", phrasesCards );
    sentencesStrings.split('|').map(
        (card: any, i: number) => {
            phrasesCards[i] = {
                id: i,
                phrase: card
            }
        }
    );
    phrasesCards.pop(); // delete the extra

    // set response length, make it a string
    let responseImages: any = new Array(content.length);
    //console.log( 'CONTENT AFTER', content );
    phrasesCards.map(
        (e: any, i: number) => {
            responseImages[i] = " ";
        }
    );

    let phrasesArray: any = sentencesStrings.split('|');
    phrasesArray.pop();

    let responseMisteries : any = [];
    let responseImagesAnswers : any = [];
    let finalAnswer: any = {};

    if(props.type === "images"){
        finalAnswer = {
            //misteries: content,
            misteries: responseMisteries,
            answers: responseImagesAnswers,
        }
    }else{
        finalAnswer = {
            misteries: responseImages,
            answers: phrasesArray,
        }
    }

    async function getNextQuestion() {        
        const response: any = await nextQuestion(props.aut, String(props.course), props.step);
        console.log( 'NEXT QUESTION', response );

        switch (props.type) {
            case "images":
                setPhrases(response.body.question.script.content.options);
                setContent(response.body.question.script.content.image_video);
                break;
            case "categories":
                setPhrases(response.body.question.script.content.sentences);
                setContent(response.body.question.script.content.categories);
                //setPhrases(["OJO", "JORGE", "OJO", "JORGE"]);
                //setContent(["OJO", "JORGE"]);
                break;
        }

        return response.body.question.script.content;
    }

    const onDragEndCategory = (result: DropResult) => {
        const { destination, source, draggableId } = result;

       
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        let current;
        let sentencesArray: any = phrasesCards;
        let imagesAnswersArray: any = contentHolder;

        

        let index: any = draggableId.split('draggable-')[1];
        current = {
            id: index,
            phrase: sentencesStrings.split('|')[index]
        }

        let droppableIdFormat = '';
        if (props.type === "images")
            droppableIdFormat = 'droppable';
        else
            droppableIdFormat = 'droppable-';

        // set current card on hand, take out card from source
        if (source.droppableId !== 'categoryDroppable') {
            let sourceArray: any = source.droppableId.split(droppableIdFormat)[0];
            imagesAnswersArray[sourceArray].cards.splice(source.index, 1);

            if (props.type === "images" ){
                console.log('sacar', sentencesStrings.split('|')[index]);
                for( let i = 0; i < responseImagesAnswers.length; i++ ){
                    if( responseImagesAnswers[i] == sentencesStrings.split('|')[index] ){
                        responseImagesAnswers.splice( i, 1 );
                        responseMisteries.splice(i, 1);
                        break;
                    }
                }
            }
        } else {
            
            if (props.type === "images" && destination.droppableId.split( droppableIdFormat )[1] ){
                console.log( 'insertar', sentencesStrings.split('|')[index]);    
                responseImagesAnswers.push( sentencesStrings.split('|')[index] );
                //console.log( destination.droppableId.split(droppableIdFormat)[0], destination.droppableId.split(droppableIdFormat)[1] );
                responseMisteries.push( destination.droppableId.split(droppableIdFormat)[1] );
            }         
            //responseImagesAnswers.push( sentencesStrings.split('|')[index] );
            

            sentencesArray.splice(source.index, 1);
        }

        //console.log( current,  );
        
        // replace or add current card on hand into droppable
        if (destination.droppableId !== 'categoryDroppable') {
            let destinationArray: any = destination.droppableId.split(droppableIdFormat)[0];
            imagesAnswersArray[destinationArray].cards.splice(destination.index, 0, current);

           

            if (props.type !== 'images'){
                responseImages[draggableId.split('draggable-')[1]] = destination.droppableId.split(droppableIdFormat)[1];

                
            }else{
                responseImages[destination.droppableId.split(droppableIdFormat)[0]] = phrasesArray[draggableId.split('draggable-')[1]];
            }
        } else {
            sentencesArray.splice(destination.index, 0, current);
        }
        
        //console.log(responseImages, result);
    }

    function reset() {
        console.log("RESSETING");
        getNextQuestion();
        setArrays();
        //phrasesCards = '';
    }

    function setArrays() {
        setPhrases(props.phrases);        
        let multiArray: any[] = [];
        for (let i = 0; i < props.droppableContent.length; i++) {
            let obj: any = {
                id: String(i),
                name: content[i].url ? content[i].url : content[i],
                cards: []
            }
            multiArray.push(obj);
        }
        setContentHolder(multiArray);
    }

    function getExtension(url: string) {
        let extension: any = url.split('.');

        switch (extension[extension.length - 1]) {
            case 'jpg':
                return <img src={"" + url} />
            case 'png':
                return <img src={"" + url} />
            case 'mp4':
                return <video width="200" height="200" controls>
                    <source src={"" + url} type={"video/mp4"} />
                </video>
            case 'mp3':
                return <audio controls>
                    <source src={"" + url} type={"audio/mpeg"} />
                </audio>
        }
    }

    useEffect(() => {
        console.log( 'reset on use effect' );
        reset();
    }, [props]);

    return (
        <div className='lection'>
            <div className='lection-header'>
                <QuestionHeader current={props.current} courseInfo={props.courseInfo} question={props.step.question} lesson={props.step.lesson} totalQuestionNumber={props.courseInfo.course_data.lessons.length} />
                <MediaFile media_type={props.current.media_type} media_file={props.current.media_file} />
            </div>
            <div className='lection-body'>
                <div className='lection-container'>
                    <DragDropContext onDragEnd={onDragEndCategory}>
                        <div className='category-row'>
                            {
                                props.type === "images" ?
                                    contentHolder?.map(
                                        (image: any, index: any) => (
                                            <ImageDroppabble key={"dropkey" + index} imageUrl={image.name} imagesCards={image.cards} imageId={index + 'droppable' + image.name} />
                                        )
                                    )
                                    :
                                    contentHolder?.map(
                                        (category: Category, index: number) => (
                                            <CategoryDroppable key={"dropkey" + index} category={category.name} categoryCardsHolder={category.cards} catogryId={index + 'droppable-' + category.name} />
                                        )
                                    )
                            }
                        </div>
                        <Droppable droppableId='categoryDroppable'>
                            {
                                (provided) => (
                                    <div className='lection-optionsContainer' ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            phrasesCards?.map(
                                                (card: any, index: number) => (
                                                    <AnswerDraggable key={"key" + card.id} answerText={card.phrase} answerId={card.id} index={index} />
                                                )
                                            )
                                        }
                                        {provided.placeholder}
                                    </div>

                                )
                            }
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
            <div className='separator separator-foo' />
            <div className='lection-footer'>
                <img className='answerIconBtn' src={require('../../Assets/icons/answer.png')} onClick={() => {
                    props.onRespond(finalAnswer);
                    //reset();
                }} />
                <img className='redoIconBtn' src={require('../../Assets/icons/redo.png')} onClick={reset} />
            </div>
        </div>
    );
};


export default DragNDropMultiple;
