import { MenuItem, Select } from "@mui/material";
import Cookies from "js-cookie";
import React, { useContext } from "react";
//import './layout.css'


class Layout extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    Logout() {
        Cookies.remove('user');
        window.location.href = "/";
    }

    render() {

        const myBackgroundImage = {
            backgroundImage: "url("+ this.props.imageUrl +")",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };

        return (
            <div className="wrapper">
                <div className={"Layout" + " " + this.props._classes} style={this.props.imageUrl? myBackgroundImage : {}}>
                    <div className="header-parent">
                        <div className="wrap container-fluid">
                            <div className="Layout-header">
                                <a href="/" className='Layout-header-logo'>
                                    <img
                                        src={require('../Assets/logo-blanco.png')}
                                    />
                                </a>
                                <div className="Layout-header-avatarContainer">
                                    <div className='Layout-header-avatar'>
                                        <img
                                            src={require('../Assets/icons/f.png')}
                                        />
                                    </div>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={1}
                                        label="Username"
                                        sx={{ color: "white" }}
                                        onChange={() => { }}
                                    >
                                        <MenuItem value={1}>{this.props.aut.name}</MenuItem>
                                        <MenuItem value={2} onClick={() => { this.Logout(); }}>Logout</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Layout-body">
                        {this.props.children}
                    </div>
                    <div className="Layout-footer">
                        <div className="wrap container-fluid">
                            <div className="Layout-footer-body">
                                <div className="Layout-footer-textContainer">
                                    <div className="Layout-footer-title-text">FALE MAIS</div>
                                    <div className="Layout-footer-body-text">Av. Providencia 1208 - Santiago de Chile</div>
                                    <div className="Layout-footer-body-text">Todos los Derechos Reservados.</div>
                                </div>
                                <ul className="Layout-footer-iconContainer">
                                    <li>
                                        <a href="https://www.facebook.com/falemaispt/" target="_blank">
                                            <img
                                                className="Layout-footer-icon"
                                                src={require('../Assets/icons/ico-facebook.png')}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/falemaispt/" target="_blank">
                                            <img
                                                className="Layout-footer-icon"
                                                src={require('../Assets/icons/ico-instagram.png')}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/falemaispt/" target="_blank">
                                            <img
                                                className="Layout-footer-icon"
                                                src={require('../Assets/icons/ico-linkedin.png')}
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/*<img
                            className='Layout-footer-image'
                            src={require('../Assets/images/footer.png')}
        />*/}
                    </div>
                </div>
            </div>
        );
    }
};

export default Layout;