import { useContext, useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { nextQuestion } from '../../services/studentCourses';
import MediaFile from './QuestionComponents/mediaFile';
import PhraseOrderItem from './QuestionComponents/phraseOrderItem';
import QuestionHeader from './QuestionComponents/question-header';
//import './lection.css';

interface Props {
    phrases: string[],
    onRespond: (value: any) => void,
    courseInfo: any,
    step: any,
    current: any,
    course: any,
    aut: any,
}

const DragNDropPhraseOrder = (props: Props) => {
    //const { handleLogin, token, hostname, isLoged } = useContext(AdminContext);
    const [phrase, setPhrase] = useState<any>(props.phrases);
    const [options, setOptions] = useState<any>();
    const [answer, setAnswer] = useState<any>('');

    const onDragEndComplete = (result: DropResult) => {
        const { destination, source, draggableId } = result;
        //console.log("DRAG: ", result, destination?.droppableId.split('droppable'));
              

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (destination.droppableId.split('droppable')[1] !== source.droppableId.split('droppable')[1]) {
            return;
        }

        //console.log( options );  

        
        let dropIndex = phrase?.findIndex((x: any) => destination.droppableId.split('droppable')[1] === x);
        let draggedIndex : any = Number( source.droppableId.split('droppable')[0] );
        let targetIndex : any = Number( destination.droppableId.split('droppable')[0] );

        //console.log( dropIndex, draggedIndex, oldIndex, options );
        
        let _phraseArray : Array<any> = options[dropIndex].phrase;
        let _optionSelected = _phraseArray[draggedIndex];
        
        //console.log( _phraseArray, _optionSelected, targetIndex );

        //delete _phraseArray[draggedIndex];
        _phraseArray = _phraseArray.filter( (item, index) => {
            //console.log( item, index );
            return index !== draggedIndex;
        });

        //_phraseArray = _phraseArray.slice( draggedIndex, 1 );
        //console.log( _phraseArray );

        _phraseArray.splice( targetIndex, 0, _optionSelected );
        
        options[dropIndex].phrase = _phraseArray;

        //console.log( options );
        
        let newAnswer = new Array(options.length);
        for (let i: number = 0; i < options.length; i++) {
            let answerItem = '';            

            for (let f: number = 0; f < options[i].phrase.length; f++) {
                if (f !== options[i].phrase.length - 1)
                    answerItem += options[i].phrase[f].word + " ";
                else
                    answerItem += options[i].phrase[f].word;
            }
            newAnswer[i] = answerItem;
        }
        //console.log(newAnswer);
        setAnswer(newAnswer);        

        /*
        let dropIndex = phrase?.findIndex((x: any) => destination.droppableId.split('droppable')[1] === x);
        let draggedIndex = source.droppableId.split('droppable')[0];
        let oldIndex = destination.droppableId.split('droppable')[0];

        let current = options[dropIndex].phrase[draggedIndex];
        let old = options[dropIndex].phrase[oldIndex];

        console.log(current, old);

        options[dropIndex].phrase[draggedIndex] = old;
        options[dropIndex].phrase[oldIndex] = current

        console.log(options[dropIndex]);

        let newAnswer = new Array(options.length);
        for (let i: number = 0; i < options.length; i++) {
            let answerItem = '';
            for (let f: number = 0; f < options[i].phrase.length; f++) {
                if (f !== options[i].phrase.length - 1)
                    answerItem += options[i].phrase[f].word + " ";
                else
                    answerItem += options[i].phrase[f].word;
            }
            newAnswer[i] = answerItem;
        }
        console.log(newAnswer);
        setAnswer(newAnswer);
        */
    }

    async function getNextQuestion() {
        const response: any = await nextQuestion(props.aut, String(props.course), props.step);

        setPhrase(response.body.question.script.content.phrases);
        console.log(response.body.question.script.content.phrases);
        return response.body.question.script.content;
    }

    function Reset() {
        //getNextQuestion();
        //setPhrase(props.phrases);
        console.log(props.phrases);
        const array:any = props.phrases;
        let stringOptions: any = '';
        array?.map(
            (phrase: any) => {
                stringOptions += phrase + "|"
            }
        );

        console.log(stringOptions);
        let myOptions: any = new Array(stringOptions.split('|').length - 1);
        for (let i: number = 0; i < myOptions.length; i++) {
            myOptions[i] = {
                id: i,
                phrase: new Array(array[i].split(' ').length)
            }
            for (let f: number = 0; f < array[i].split(' ').length; f++) {
                myOptions[i].phrase[f] = {
                    id: f,
                    word: new Array(1)
                }
                myOptions[i].phrase[f].word[0] = array[i].split(' ')[f];
            }
        }
        console.log(myOptions);
        setOptions(myOptions);

        let newAnswer = new Array(myOptions.length);
        for (let i: number = 0; i < myOptions.length; i++) {
            let answerItem = '';            

            for (let f: number = 0; f < myOptions[i].phrase.length; f++) {
                if (f !== myOptions[i].phrase.length - 1)
                    answerItem += myOptions[i].phrase[f].word + " ";
                else
                    answerItem += myOptions[i].phrase[f].word;
            }
            newAnswer[i] = answerItem;
        }
        //console.log(newAnswer);
        setAnswer(newAnswer);
    }

    useEffect(() => {
        Reset();
    }, [props]);

    return (
        <div className='lection'>
            <div className='lection-header'>
                <QuestionHeader current={props.current} courseInfo={props.courseInfo} lesson={props.step.lesson} question={props.step.question} totalQuestionNumber={props.courseInfo.course_data.lessons.length} />
                <MediaFile media_type={props.current.media_type} media_file={props.current.media_file} />
            </div>
            <div className='lection-body'>
                <div className='lection-container'>
                    <div className='lection-columnContainer'>
                        <DragDropContext onDragEnd={onDragEndComplete}>
                            {
                                options?.map(
                                    (option: any, index: number) => (
                                        <div key={index} className="custom-indexer" style={{ margin: "0.7rem 0" }}>
                                            <div className="circle-number">{index + 1}</div>
                                            <PhraseOrderItem key={index} options={option.phrase} droppableId={phrase[index]} />
                                        </div>
                                    )
                                )
                            }
                        </DragDropContext>
                    </div>
                </div>
            </div>
            <div className='separator separator-foo' />
            <div className='lection-footer'>
                <img className='answerIconBtn' src={require('../../Assets/icons/answer.png')} onClick={() => {
                    props.onRespond(answer);
                    //Reset();
                }} />
                <img className='redoIconBtn' src={require('../../Assets/icons/redo.png')} onClick={Reset} />
            </div>
        </div>
    );
};


export default DragNDropPhraseOrder;
