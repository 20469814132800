import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const loader : any = document.querySelector('.bgload');
const showLoader = () => loader.classList.remove('loader--hide');
const hideLoader = () => loader.classList.add('loader--hide');

showLoader();

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
    <App hideLoader={hideLoader}
        showLoader={showLoader}/>
);

/*
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);



        <Route path='/cursos' element={<HomeCourses />}>
          <Route path='lecture' element={<Lecture />} />
          <Route path='lecture-end' element={<LectionEnd />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/registro' element={<Register />} />
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
