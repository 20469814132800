import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Layout from '../../components/layout';
import { getTakenCourse, likeOrDislike } from '../../services/studentCourses';
//import './lecture-end.css';

const LectionEnd = (props: any) => {
    //const { handleLogin, token, hostname, isLoged } = useContext(AdminContext);
    let { course, lesson }: any = useParams();

    const [loading, setLoading] = useState(true);
    const [courseInfo, setCourseInfo] = useState<any>();
    const [titleText, setTitleText] = useState<string>('Parabéns, você completou a lição!');
    const [encourageText, setEncourageText] = useState('Muito bem, continue assim!');
    const [like, setLike] = useState<any>(null);

    let perc = courseInfo?.course_data.lessons[lesson].percent_complete;
    let total = courseInfo?.course_data.lessons[lesson].total_questions;
    let questionArray: any = new Array(total);

    let current;
    if (perc > 0)
        current = (total / perc) * 100;
    else
        current = 0

    for (let i: number = 0; i < questionArray.length; i++) {
        if (i >= current) {
            questionArray[i] = false;
        } else {
            questionArray[i] = true;
        }
    }

    async function getQuestionStep() {
        const response: any = await getTakenCourse(props.aut.token, String(course));
        setCourseInfo(response);
        setTexts(response.course_data.lessons[lesson].percent_complete);
        setLoading(false);
        console.log(response);
    }

    function setTexts(value: any) {
        if (value >= 25 && value < 50) {
            setTitleText('Continue aprendendo, você completou a lição!');
            setEncourageText('Você pode melhorar, continue aprendendo!');
        } else if (value >= 50 && value < 75) {
            setTitleText('Bom trabalho, você completou a lição!');
            setEncourageText('Bom trabalho, continue assim!');
        } else if (value >= 75 && value < 100) {
            setTitleText('Muito bem, você completou a lição!');
            setEncourageText('Muito bem, continue assim!');
        } else if (value === 100) {
            setTitleText('Excelente, continue assim!');
            setEncourageText('Parabéns, você completou a lição!');
        } else {
            setTitleText('Parabéns, você completou a lição!');
            setEncourageText('Muito bem, continue assim!');
        }
    }

    async function setLikeOrDislike(value: boolean) {
        const response = await likeOrDislike(props.aut.token, String(course), { lesson: courseInfo.course_data.lessons[lesson].id, like: value });
        console.log(response);
    }

    useEffect(() => {
        if(like !== null)
        setLikeOrDislike(like);
    }, [like])

    useEffect(() => {
        getQuestionStep();
    }, []);

    return (
        <Layout aut={props.aut} _classes="course-layout" imageUrl={typeof courseInfo !== 'undefined' && courseInfo.course_data.media_file !== null ? courseInfo.course_data.media_file : ''}>
            {
                loading ? null :
                    <div className="wrap container-fluid">
                        <div className='lecture-end'>
                            <div className='lecture-endHeader'>
                                <div className='lecture-endHeader-container'>
                                    <div className='lecture-avatarHeader'>
                                        <div className='lecture-avatar'>
                                            <div className="avatarImage">
                                                {
                                                    courseInfo.course_data.lessons[lesson].media_file !== null ?
                                                        <img src={"" + courseInfo.course_data.lessons[lesson].media_file} />
                                                        :
                                                        <img src={require('../../Assets/images/curso-small.jpg')} />
                                                }
                                            </div>
                                            <div className='avatarTextContainer'>
                                                <div className='avatar-title'>{courseInfo.course_data.name}</div>
                                                <div className='avatar-subtitle'>{courseInfo.course_data.level_class_name}</div>
                                            </div>
                                        </div>
                                        <div className='lecture-questionTitle'>{courseInfo.course_data.lessons[lesson].title}</div>
                                    </div>
                                    <div className='separator' />
                                    <div className='lecture-questionTitle'>PERGUNTAS DESTA LIÇÃO</div>
                                    <div className='plan-progressContainer'>
                                        {
                                            questionArray?.map(
                                                (question: any, index: number) => (
                                                    question ?
                                                        <div key={index}>
                                                            <img src={require('../../Assets/icons/crossbar-red.png')} />
                                                        </div>
                                                        :
                                                        null
                                                )
                                            )
                                        }
                                        <div>
                                            <img src={require('../../Assets/icons/plane-red.png')} />
                                        </div>
                                        {
                                            !questionArray?.map(
                                                (question: any, index: number) => (
                                                    !question ?
                                                        <div key={index}>
                                                            <img src={require('../../Assets/icons/crossbar-grey.png')} />
                                                        </div>
                                                        :
                                                        null
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='lecture-endBody'>
                                <div className='lecture-endBodyContainer'>
                                    <div className='lecture-endBody-title'>{titleText}</div>
                                    <div className='lecture-endBody-prctContainer'>
                                        <div className='lecture-endBody-prctTextContainer'>
                                            <div className='lecture-endBody-prctText'>                                                
                                            {
                                                Math.round( Number( courseInfo.course_data.lessons[lesson].percent_complete ) )
                                                /*
                                                courseInfo.course_data.lessons[lesson].percent_complete.toString().includes('.')?
                                                courseInfo.course_data.lessons[lesson].percent_complete.toFixed(2)
                                                :
                                                courseInfo.course_data.lessons[lesson].percent_complete
                                                */
                                            }%</div>
                                            <div className='separator' />
                                            <div className='lecture-endBody-subtitle'>A sua porcentagem de progresso</div>
                                        </div>
                                    </div>
                                    <div className='lecture-endBody-encourageText'>{encourageText}</div>
                                    <div className='separator' />
                                    <div className='lecture-likeContainer'>
                                        <div className='lecture-endBody-likeText'>Você gostou da lição?</div>
                                        <div className='like-iconsContainer'>
                                            <div className={like === null || !like ? 'likeBtn' : 'likeBtn-disabled'} onClick={() => {
                                                if(like === null || like)
                                                setLike(false);
                                            }}>
                                                <img
                                                    className='lecture-endBody-likeIcon'
                                                    src={require('../../Assets/icons/no-like.png')}
                                                />
                                            </div>
                                            <div className={like === null || like ? 'likeBtn' : 'likeBtn-disabled'} onClick={() => {
                                                if(like === null || !like)
                                                setLike(true);
                                            }} >
                                                <img
                                                    className='lecture-endBody-likeIcon'
                                                    src={require('../../Assets/icons/like.png')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='separator' />
                                    <div className='lection-footer'>
                                        <a href="/" className="back" title="">Lobby</a>
                                        <a className='return-btn' href={'/course?course=' + course}>Voltar para o curso</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </Layout>
    );

};


export default LectionEnd;
