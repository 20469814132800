//import './lection.css';

import React, { useEffect, useState } from "react";
import MediaFile from "./QuestionComponents/mediaFile";
import QuestionHeader from "./QuestionComponents/question-header";
import TrueOrFalseCheckbox from "./QuestionComponents/trueOrFalseCheckbox";

interface Props {
    id: string,
    sentences: any[],
    onRespond: (value: any) => void,
    courseInfo: any,
    step: any,
    current: any
}

// aqui seteo todo en false
const TrueOrFalse = (props: Props) => {
    let responses = new Array(props.sentences.length);

    const [resetBox, setResetBox] = useState(false);

    for (let i: number = 0; i < responses.length; i++) {
        responses[i] = null;
    }

    function OnValueChange(value: any, index: number) {
        console.log(responses);
        responses[index] = value;
    }

    useEffect(() => {
        setResetBox(false);
    }, [resetBox]);

    return (
        <div className='lection'>
            <div className='lection-header'>
                <QuestionHeader current={props.current} courseInfo={props.courseInfo} lesson={props.step.lesson} question={props.step.question} totalQuestionNumber={props.courseInfo.course_data.lessons.length} />
                <MediaFile media_type={props.current.media_type} media_file={props.current.media_file} />
            </div>
            <div className='lection-body'>
                <div className='lection-container'>
                    <div className='lection-TrueOrFalseContainer'>
                        <div className='lection-ColumnContainer'>
                            {
                                !resetBox ?
                                    props.sentences.map(
                                        (sentence: any, index) => (
                                            <div key={index} className="custom-indexer">
                                                <div className="circle-number">{index + 1}</div>
                                                <TrueOrFalseCheckbox sentence={sentence} index={index} onValueChanged={(e) => OnValueChange(e, index)} />
                                            </div>
                                        )
                                    )
                                    :
                                    props.sentences.map(
                                        (sentence: any, index) => (
                                            <div key={index} className="sentence">
                                                <div className='lection-sentenceText'>{sentence.phrase}</div>
                                                <input type="checkbox" value={sentence.phrase} className='lection-sentenceCheckBox' defaultChecked={false} />
                                            </div>
                                        )
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='separator separator-foo' />
            <div className='lection-footer'>
                <img className='answerIconBtn' src={require('../../Assets/icons/answer.png')} onClick={() => {
                    props.onRespond(responses);
                    //setResetBox(true);
                }} />
            </div>
        </div>
    );
}

export default TrueOrFalse;
