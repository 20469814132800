import { useState } from "react";

interface Props {
    sentence: any,
    index: any,
    onValueChanged: (value: boolean, index: number) => void,
}

const TrueOrFalseCheckbox = (props: Props) => {
    const [value, setValue] = useState<any>(null);

    console.log(value);
    return (
        <div key={props.index} className="sentence">
            <div className='lection-sentenceText'>{props.sentence.phrase}</div>
            <div style={{ textAlign: 'center', marginRight: '1rem', marginLeft: '1rem' }}>
                <label className="custom-check verdadero">Verdadeiro
                    <input type="checkbox" value={props.sentence.phrase} className='lection-sentenceCheckBox round'
                        onChange={() => { setValue(true); props.onValueChanged(true, props.index); }} checked={value !== null? value : false} />
                    <span className="checkmark"></span>
                </label>
            </div>
            <div style={{ textAlign: 'center', marginRight: '1rem', marginLeft: '1rem' }}>
                <label className="custom-check falso">Falso
                    <input type="checkbox" value={props.sentence.phrase} className='lection-sentenceCheckBox round'
                    onChange={() => { setValue(false); props.onValueChanged(false, props.index); }} checked={value !== null? !value : false}  />
                    <span className="checkmark"></span>
                </label>
            </div>
        </div>
    );
}

export default TrueOrFalseCheckbox;