import React from "react";
import './audio.css';

class FaleAudio extends React.Component<any, any> {
    constructor(props : any) {
        super(props);  

        this.state = {
            playing: false,
            uniqueId: Date.now()
        }
    }

    playSource = (e : any) => { 
        let player = this.state.player;
        console.log( player );

        if ( player.src) {
            if ( player.paused || player.ended) {
               
                player.play();
            }else {  
                player.pause();
            }
        }
    }

    pausedMusic = (me : any, err : any) => {
        this.setState({
            playing: false
        })      
    }

    playedMusic = (me : any, err : any) => {
        this.setState({
            playing: true
        })      
    }

    componentDidMount(){
        let _player : any = document.getElementById('music-' + this.state.uniqueId);

        _player.addEventListener('ended', this.pausedMusic, false);
        _player.addEventListener('play', this.playedMusic, false);

        this.setState({
            player: _player
        })
    }

    render() {
        return ( 
            <>  <div className="play-audio-fale">
                    { this.state.playing ? 
                        <svg className="play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" onClick={this.playSource}>
                            <path d="M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z"/>
                        </svg>
                    :
                        <svg className="pause" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" onClick={this.playSource} >
                            <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"/>
                        </svg>
                    }
                    <span>Audio</span>
                </div>
                
                <audio 
                    className="audio-hidden" 
                    id={"music-" + this.state.uniqueId} 
                    src={"" + this.props.source}                   
                    >
                </audio>
            </>
        )
    }
}

export default FaleAudio;
