import { useContext, useEffect, useState } from 'react';
//import '../lection.css';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AnswerDraggable from './answerDraggable';

interface Props {
    imageUrl: string,
    imagesCards: any,
    imageId: string,
}

const ImageDroppabble = (props: Props) => {

    function getExtension(url: string) {
        let extension: any = url.split('.');

        switch (extension[extension.length - 1]) {
            case 'jpg':
                return <img src={"" + url} style={sectionStyle}/>
            case 'png':
                return <img src={"" + url} style={sectionStyle}/>
            case 'mp4':
                return <video style={sectionStyle} controls>
                    <source src={"" + url} type={"video/mp4"} />
                </video>
        }
    }

    const sectionStyle = {
        //height: '100%',
        resizeMode: "contain",
        borderRadius: '8px'
    };

    return (
        <Droppable droppableId={props.imageId}>
            {
                (provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className='category-columnImage'>
                        {
                            getExtension(props.imageUrl)
                        }
                        {
                            props.imagesCards?.map(
                                (card: any, index: any) => (
                                    <AnswerDraggable key={"key" + card.id} answerText={card.phrase} answerId={card.id} index={index} />
                                )
                            )
                        }
                        {provided.placeholder}
                    </div>
                )
            }
        </Droppable>
    );
}

export default ImageDroppabble;