import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface ProgressProps{
  progressValue:number
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        {
          props.value == 100? <LinearProgress variant="determinate" color='error' {...props} /> 
          : 
          <LinearProgress variant="determinate" color='info' {...props} /> 
        }
        
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2" color="text.inherit">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel(props:ProgressProps) {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    setProgress(props.progressValue);
    /*const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };*/
  }, []);

  return (
    <Box sx={{width:'100%', flex: 0.7}}>
      <LinearProgressWithLabel value={progress}/>
    </Box>
  );
}
