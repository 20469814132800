import { useContext, useEffect, useState } from 'react';
import CursoItem from '../../components/cursos/curso-item';
import Layout from '../../components/layout';
import { getAllTakenCourses, lastCourseMade } from '../../services/studentCourses';
import { Course } from '../../Utils/Types';
//import './home-courses.css';

import {
    BrowserRouter as Router,
    Route,
    Link,
    useNavigate
} from "react-router-dom";
import { Home } from '../home/home';
import ModalBox from '../../components/modals/modalBox';

const HomeCourses = (props: any) => {

    //const { handleLogin, token, hostname, isLoged } = useContext(AdminContext);
    const [loading, setLoading] = useState(true);
    const [courses, setCourses] = useState<Course[]>([]);
    const [freeCourses, setFreeCourses] = useState<any>();
    const [lastCourse, setLastCourse] = useState<any>();

    const [openModal, setOpenModal] = useState<boolean>(false);

    let router = useNavigate();

    function GoToCourse() {
        router("/Course", { replace: false });
    }

    async function GetCourses() {
        const response = await getAllTakenCourses(props.aut.token);
        setCourses(response.courses);
        setFreeCourses(response.freeCourses);

        const lastCourseRequest = await lastCourseMade(props.aut.token);
        console.log(lastCourseRequest);
        if(lastCourseRequest.id){
            setLastCourse(lastCourseRequest);
            setOpenModal(true);
        }else{
            setOpenModal(false);
        }
        
        setLoading(false);
    }

    useEffect(() => {
        GetCourses();
    }, []);

    return (
        <Layout aut={props.aut} _classes="home-layout">
            {
                lastCourse !== undefined?
                    <ModalBox openModal={openModal} onModalClose={setOpenModal} lastCourse={lastCourse} />
                    : null
            }
            <Home />
            {
                loading ? null :
                    <div className="wrap container-fluid">
                        <div className='home-coursesContainer'>
                            <div className='slider-title'>Os seus cursos</div>
                            <div className='home-coursesSlider row'>
                                {
                                    courses.map(
                                        (course: Course) => (
                                            <CursoItem key={course.id} onButtonPressed={() => { GoToCourse() }} course={course} tipo={'pago'}/>
                                        )
                                    )
                                }
                                {
                                    freeCourses.map(
                                        (freeCourse: any) => (
                                            <CursoItem key={freeCourse.id} onButtonPressed={() => { GoToCourse() }} course={freeCourse} token={props.aut.token} tipo={'free'}/>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
            }
        </Layout>
    );
};


export default HomeCourses;
