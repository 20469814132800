import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Container, Grid, Link, Modal, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { register } from '../../services/registerService';
import Cookies from 'js-cookie';
import { login } from '../../services/loginService';
import { userProfile } from '../../services/userService';
import { useNavigate } from "react-router-dom";

const Register = (props: any) => {

    //const { handleLogin, token, hostname, isLoged } = useContext(AdminContext);
    const [enableLoginButton, setEnableLoginButton] = useState(true);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalMsg, setModalMsg] = useState<any>('Error');

    let router = useNavigate();
    const formik = useFormik({
        initialValues: {
            name: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            repeatPassword: '',
        },
        validationSchema: Yup.object({
            name: Yup
                .string()
                .max(255)
                .required(
                    'Nombre es requerido'),
            lastName: Yup
                .string()
                .max(255)
                .required(
                    'Apelldio es requerido'),
            email: Yup
                .string()
                .email(
                    'Debe ser un mail valido')
                .max(255)
                .required(
                    'Email es requerido'),
            phone: Yup
                .string()
                .max(255)
                .required(
                    'Teléfono es requerido'),
            password: Yup
                .string()
                .max(255)
                .required(
                    'Contraseña es requerida'),
            repeatPassword: Yup
                .string()
                .max(255)
                .required(
                    'Contraseña es requerida')
        }),
        onSubmit: (values: any) => {
            Submit(values);
        }
    });

    const Submit = async (values: any) => {
        const response = await register(values);
        console.log(response);
        if (response.success) {

            const profile = await userProfile(response.body.token);
            if (profile) {
                const userInfo = { name: profile, ...response.body }
                Cookies.set('user', JSON.stringify(userInfo));
                props.state.setState({
                    name: profile,
                    token: response.body.token
                });
                router("/", { replace: false });
            }
        } else {
            setModalMsg(response.errors[0].msg);
            setOpenModal(true);
        }
    }

    return loading ? null : (
        <div className='wrapper login-wrapper'>
            <div className='Register'>
                <Modal
                    open={openModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="register-modal-container">
                        <div className="register-modal-title">Hubo un error al registrarse:</div>
                        <div className="register-modal-description">{modalMsg}</div>
                        <div className="register-modal-btnContainer">
                            <button className="register-modal-cancelBtn" onClick={() => setOpenModal(false)}>Aceptar</button>
                        </div>
                    </div>
                </Modal>
                <Container maxWidth="sm" className='RegisterContainer'>
                    <div className='RegisterContainer-header'>
                        <div className='RegisterContainer-title'>
                            ¡Regístrate con Nosotros!
                        </div>
                        <img
                            className='RegisterContainer-headImage'
                            src={require('../../Assets/images/welcome.jpg')}
                        />
                        <img
                            className='icon'
                            src={require('../../Assets/icons/f.png')}
                        />
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='RegisterContainer-inputfield'>
                            <div className='RegisterContainer-inputTitle'>Nombre</div>
                            <TextField
                                error={Boolean(formik.touched.name && formik.errors.name)}
                                fullWidth
                                helperText={formik.touched.name && formik.errors.name}
                                margin="normal"
                                name="name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={formik.values.name}
                                variant="outlined"
                                onClick={() => { setEnableLoginButton(true); }}
                            />
                        </div>
                        <div className='RegisterContainer-inputfield'>
                            <div className='RegisterContainer-inputTitle'>Apellido</div>
                            <TextField
                                error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                fullWidth
                                helperText={formik.touched.lastName && formik.errors.lastName}
                                margin="normal"
                                name="lastName"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={formik.values.lastName}
                                variant="outlined"
                                onClick={() => { setEnableLoginButton(true); }}
                            />
                        </div>
                        <div className='RegisterContainer-inputfield'>
                            <div className='RegisterContainer-inputTitle'>Email</div>
                            <TextField
                                error={Boolean(formik.touched.email && formik.errors.email)}
                                fullWidth
                                helperText={formik.touched.email && formik.errors.email}
                                margin="normal"
                                name="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="email"
                                value={formik.values.email}
                                variant="outlined"
                                onClick={() => { setEnableLoginButton(true); }}
                            />
                        </div>
                        <div className='RegisterContainer-inputfield'>
                            <div className='RegisterContainer-inputTitle'>Teléfono</div>
                            <TextField
                                error={Boolean(formik.touched.phone && formik.errors.phone)}
                                fullWidth
                                helperText={formik.touched.phone && formik.errors.phone}
                                margin="normal"
                                name="phone"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={formik.values.phone}
                                variant="outlined"
                                onClick={() => { setEnableLoginButton(true); }}
                            />
                        </div>
                        <div className='RegisterContainer-inputfield'>
                            <div className='RegisterContainer-inputTitle'>Contraseña</div>
                            <TextField
                                error={Boolean(formik.touched.password && formik.errors.password)}
                                fullWidth
                                helperText={formik.touched.password && formik.errors.password}
                                margin="normal"
                                name="password"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="password"
                                value={formik.values.password}
                                variant="outlined"
                                onClick={() => { setEnableLoginButton(true); }}
                            />
                        </div>
                        <div className='RegisterContainer-inputfield'>
                            <div className='RegisterContainer-inputTitle'>Repetir Contraseña</div>
                            <TextField
                                error={Boolean(formik.touched.repeatPassword && formik.errors.repeatPassword)}
                                fullWidth
                                helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
                                margin="normal"
                                name="repeatPassword"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="password"
                                value={formik.values.repeatPassword}
                                variant="outlined"
                                onClick={() => { setEnableLoginButton(true); }}
                            />
                        </div>
                        <button
                            className='RegisterContainer-button'
                            disabled={!enableLoginButton}
                            type="submit"
                        >
                            Registrarse
                        </button>
                    </form>
                </Container>
            </div>
        </div>
    );
};


export default Register;
