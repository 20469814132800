
import { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layout';
import CompleteSentenceExercise from '../../components/lection/completeSentence';
import DragNDropCompleteSentence from '../../components/lection/dragNDropCompleteSentence';
import TrueOrFalse from '../../components/lection/trueOrFalse';
//import './lecture.css';
import DragNDropCategory from '../../components/lection/dragNDropCategory';
import DragNDropPhraseOrder from '../../components/lection/dragNDropPhraseOrder';
import { useNavigate, useParams } from 'react-router';
import { getTakenCourse, nextQuestion, resolveQuestion } from '../../services/studentCourses';
import DragNDropImage from '../../components/lection/dragNDropImage';
import ModalAnswer from '../../components/modals/modalAnswer';
import LectureHeader from '../../components/lesson-header/lection-header';
import DragNDropMultiple from '../../components/lection/dragNDropMultiple';
import { WindowSharp } from '@mui/icons-material';

export const Lecture = (props: any) => {
    let { course } = useParams();

    const [nextStep, setNextStep] = useState<any>(null);
    const [currentQuestion, setCurrentQuestion] = useState<any>(null);

    const [currentComponent, setComponent] = useState<any>(null);
    const [lectionResponse, setLectionResponse] = useState<any>(null);

    const [showModal, setShowModal] = useState(false);
    const [isCorrect, setIsCorrect] = useState(false);
    const [modalMode, setModalMode] = useState('');
    //const [showModalNext, setShowModalNext] = useState('');

    const [info, setInfo] = useState<any>(null);
    //let isCorrect = false;
    //let showModal = false;
    let btnIsDisabled = false;

    const router = useNavigate();

    // Quadra useEffect :s
    // get taken course
    useEffect(() => {
        getQuestionInfo();
    }, []);

    // next question
    useEffect(() => {
        if (nextStep !== null && typeof nextStep !== 'undefined') {
            getNextQuestion();
        }
    }, [nextStep])

    // cambio de ejercicio
    useEffect(() => {
        if (typeof currentQuestion !== 'undefined' && currentQuestion !== null) {
            getExercise(currentQuestion.body.question);
            setTimeout(() => {
                setShowModal(false);
            }, 1500); //cambiar tiempo de respuesta falsa
            //showModal = false;
            //setShowModal(false);
            //window.location.reload();
            console.log('LOG DE ACTUALIZACION DE PREGUNTA',currentQuestion);
        }
    }, [currentQuestion]);

    // respuesta de usuario
    useEffect(() => {
        //console.log(lectionResponse);
        if (lectionResponse !== null && lectionResponse.response !== null)
            Respond();
    }, [lectionResponse])

    async function getQuestionInfo() {
        const response: any = await getTakenCourse(props.aut.token, String(course));
        setInfo(response);
        if (response.step !== null) {
            setNextStep(response.step);
        }
        else {
            router("/course/" + course + "/results/" + (response.total_lesson - 1), { replace: false });
        }
    }

    async function getNextQuestion() {
        const response: any = await nextQuestion(props.aut.token, String(course), nextStep);
        setCurrentQuestion(response);
    }

    async function resolveQuestionResponse() {
        let responses: any = {};
        if (lectionResponse.phrases) {
            responses = {
                lesson: nextStep.lesson,
                question: nextStep.question,
                response: lectionResponse.responses,
                phrases: lectionResponse.phrases
            }
        } else if (lectionResponse.misteries) {
            responses = {
                lesson: nextStep.lesson,
                question: nextStep.question,
                misteries: lectionResponse.misteries,
                answers: lectionResponse.answers
            }
        } else {
            responses = {
                lesson: nextStep.lesson,
                question: nextStep.question,
                response: lectionResponse,
            }
        }

        const response = await resolveQuestion(props.aut.token, String(course), responses);
        //console.log(response);
        return response.body.isCorrect;
    }

    function goToNextQuestion() {
        if (currentQuestion.body.nextCoord !== null) {
            setTimeout( function(){
                setShowModal(false);
                window.location.reload();
            }, 2500 );
            //setNextStep(currentQuestion.body.nextCoord);

        } else {
            router("/course/" + course + "/results/" + nextStep.lesson, { replace: false });
        }
    }

    async function Respond() {
        // enviar respuest
        //return;
        let isResolveCorrect = true;
        if (typeof currentQuestion !== 'undefined' && currentQuestion !== null) {
           
            const resolve: any = await resolveQuestionResponse();
            //console.log("RESOLVE", resolve);
            if (Array.isArray(resolve)) {
                resolve.forEach( (e : any) => {
                    if (e !== true)
                        isResolveCorrect = false;
                }
                );
                //setShowModalNext('next');
                
                if (isResolveCorrect){
                    setModalMode('correct');
                    setShowModal(true);
                    goToNextQuestion();
                }else{
                    setModalMode('incorrect');
                    setShowModal(true);
                }
            } else {
                if (resolve === true)
                {
                    setModalMode('correct');
                    setShowModal(true);

                    goToNextQuestion();
                }
                else if (resolve === false) {
                    isResolveCorrect = false;
                    setModalMode('incorrect');
                    setShowModal(true);
                } else if (resolve === "next") {
                    //setShowModalNext('tries');       
                    setModalMode('next');             
                    setShowModal(true);                    
                    goToNextQuestion();
                }
            }
            setIsCorrect(isResolveCorrect);
            //setShowModal(true);
            btnIsDisabled = false;

            if (isResolveCorrect === false) {

                getNextQuestion();
            }
            //window.location.reload();
        }
    }

    function getExercise(current: any) {
        switch (current.script.kind) {
            case 'complete_drag_n_drop':
                //setComponent(<DragNDropCompleteSentence current={current} questions={["________________ soy ________________", "________________ multiple", "Esto es una ________________ multiple para ________________"]} options={current.script.content.options} onRespond={setLectionResponse} step={nextStep} courseInfo={info} />);
                setComponent(<DragNDropCompleteSentence current={current} questions={current.script.content.phrases} options={current.script.content.options} onRespond={setLectionResponse} step={nextStep} courseInfo={info} />);
                break;
            case 'complete_phrase':
                setComponent(<CompleteSentenceExercise current={current} sentences={current.script.content.phrases} onRespond={setLectionResponse} btnDisabled={btnIsDisabled} step={nextStep} courseInfo={info} />);
                //setComponent(<CompleteSentenceExercise current={current} sentences={["________________ soy ________________", "________________ multiple", "Esto es una ________________ multiple para ________________"]} onRespond={setLectionResponse} btnDisabled={btnIsDisabled} step={nextStep} courseInfo={info}/>);
                break;
            case 'organize_phrase':
                setComponent(<DragNDropPhraseOrder current={current} phrases={current.script.content.phrases} onRespond={setLectionResponse} step={nextStep} courseInfo={info} aut={props.aut.token} course={course}/>);
                //setComponent(<DragNDropPhraseOrder current={current} phrases={["é engenheiro ele", "soy jorge hola"]} onRespond={setLectionResponse} step={nextStep} courseInfo={info}/>);
                break;
            case 'asociate_image':
                setComponent(<DragNDropMultiple current={current}
                    phrases={current.script.content.options}
                    droppableContent={current.script.content.image_video}
                    onRespond={setLectionResponse} course={course} aut={props.aut.token} step={nextStep} courseInfo={info} type={'images'} />);
                break;
            case 'asociate_category':
                setComponent(<DragNDropMultiple current={current}
                    phrases={current.script.content.sentences}
                    droppableContent={current.script.content.categories}
                    onRespond={setLectionResponse} course={course} aut={props.aut.token} step={nextStep} courseInfo={info} type={'categories'} />);
                break;
            case 'true_or_false':
                setComponent(<TrueOrFalse current={current} sentences={current.script.content.sentences} onRespond={setLectionResponse} id={current.id} step={nextStep} courseInfo={info} />);
                break;
        }
    }

    return (
        <Layout aut={props.aut} _classes={'course-layout'} imageUrl={info !== null && info.course_data.media_file !== null ? info.course_data.media_file : ''}>
            <div className='wrap conatiner-fluid'>
                {
                    info !== null ?
                        <LectureHeader courseInfo={info} step={nextStep} courseImage={info.course_data.lessons[nextStep.lesson].media_file !== null ? info.course_data.lessons[nextStep.lesson].media_file : ''} />
                        : null
                }
                {
                    currentComponent
                }
            </div>
            <ModalAnswer tries={  ( currentQuestion?.body.question.tries ?? 0 ) + 0 } modalMode={modalMode} showModal={showModal} onModalClose={() => setShowModal(false)} />
            {/*<ModalAnswer isCorrect={isCorrect} showModal={showModal} onModalClose={() => setShowModal(false)} nextQuestionMode={showModalNext}/> */}
        </Layout>
    );

};
