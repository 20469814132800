import { useContext, useEffect, useState } from 'react';
//import '../lection.css';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AnswerDraggable from './answerDraggable';

interface Props {
    sentence: string,
    questionId: any,
    answerCards: any,
}

const QuestionDroppable = (props: Props) => {
    //let textArray: any = props.sentence.split('________________');

    let textArray : any = props.sentence.split(' ');
    
    return (
        <div className='lection-question-container' style={{ flexWrap : 'wrap' }}>
            { textArray?.map( (text: any, i: number) => (                               
                <div key={"drop-key-" + i} className='lection-question' style={{ marginRight : "0.5rem"}}>         
                    {
                        text.trim() != '________________' ?
                            text.trim()
                        :
                        <Droppable droppableId={i + '-' + props.questionId + 'droppable' + props.sentence}>
                            { (provided) => (
                            <div className="slot-area" ref={provided.innerRef} {...provided.droppableProps} style={{ marginRight: 0 }}>
                                {
                                    props.answerCards?.cards[i] !== undefined && props.answerCards?.cards[i] !== '' ?
                                        <div key={"card-key-" + i} className='lection-questionSlot' >
                                            <AnswerDraggable answerId={ props.questionId + '-' + props.answerCards?.cards[i].id + '-' + i} index={i} answerText={props.answerCards?.cards[i].phrase} />
                                        </div>
                                        :
                                        <div key={"card-key-" + i} className='lection-questionSlot' ></div>
                                }
                                {provided.placeholder}
                            </div>
                            )}
                        </Droppable>
                    }            
                    {/* i !== textArray.length - 1 ? <>
                    {
                        text.length > 0 ?
                        <div className='lection-questionText'>{text}</div>
                        : null
                    }
                    <Droppable droppableId={i + 'droppable' + props.sentence}>
                    { (provided) => (
                        <div className="slot-area" ref={provided.innerRef} {...provided.droppableProps}>
                            {
                                props.answerCards?.cards[i] !== undefined && props.answerCards?.cards[i] !== '' ?
                                    <div key={"card-key-" + i} className='lection-questionSlot' >
                                        <AnswerDraggable answerId={props.answerCards?.cards[i].id} index={i} answerText={props.answerCards?.cards[i].phrase} />
                                    </div>
                                    :
                                    <div key={"card-key-" + i} className='lection-questionSlot' ></div>
                            }
                            {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                    </> : 
                    <div className='lection-questionText'>{text}</div>
                    */}
                </div>
            ))}
        </div >
    );

    /*
    return (
        <div className='lection-question-container'>
            { textArray?.map( (text: any, i: number) => (               
                <div key={"drop-key-" + i} className='lection-question'> 
                { i !== textArray.length - 1 ? <>
                    {
                        text.length > 0 ?
                        <div className='lection-questionText'>{text}</div>
                        : null
                    }
                    <Droppable droppableId={i + 'droppable' + props.sentence}>
                    { (provided) => (
                        <div className="slot-area" ref={provided.innerRef} {...provided.droppableProps}>
                            {
                                props.answerCards?.cards[i] !== undefined && props.answerCards?.cards[i] !== '' ?
                                    <div key={"card-key-" + i} className='lection-questionSlot' >
                                        <AnswerDraggable answerId={props.answerCards?.cards[i].id} index={i} answerText={props.answerCards?.cards[i].phrase} />
                                    </div>
                                    :
                                    <div key={"card-key-" + i} className='lection-questionSlot' ></div>
                            }
                            {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                    </> : 
                    <div className='lection-questionText'>{text}</div>
                }
                </div>
            ))}
        </div >
    );
    */
}

export default QuestionDroppable;