import React, { useEffect, useRef } from 'react';
import Layout from '../../components/layout';
import { getTakenCourse, resetTakenCourse } from '../../services/studentCourses';
//import './course.css';
import LinearWithValueLabel from './LinearWithValueLabel';

export class CoursePage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        let idCourse: string = String(new URLSearchParams(window.location.search).get('course'));

        this.state = {
            idCurso: idCourse,
            curso: null,
        }
        // si no hay datos, reedireccionar al 404
    }

    resetTakenCourse = (e : any) => {
        e.preventDefault();
        e.stopPropagation();
        
        const href = e.target.href;
        const courseId = this.state.idCurso;

        const reset : any = resetTakenCourse( this.props.aut.token, courseId, href );
        return;
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    async componentDidMount() {
        const currentCurso = await getTakenCourse(this.props.aut.token, this.state.idCurso);

        this.setState({
            curso: currentCurso,
        });
    }

    render() {
        //let currentCourse: Course = this.state.curso;

        return (
            <Layout aut={this.props.aut} _classes="course-layout" imageUrl={this.state.curso !== null ? this.state.curso.course_data.media_file : ''}>
                {
                    this.state.curso !== null ?
                        <div className="wrap container-fluid">
                            <div className='Course'>
                                <div className='course-headContainer'>
                                    <div className='title-container'>
                                        <div className='title'>{this.state.curso.course_data.title}</div>
                                        <div className='level-header'>{this.state.curso.course_data.level_class_name}</div>
                                    </div>
                                    <div className='separator' />
                                    <div className='description-header'>{this.state.curso.course_data.body}</div>
                                </div>
                                <div className='course-body'>
                                    <div className='title-body'>Lições do curso</div>
                                    <div id='plane' className='plan-progressContainer'>
                                        {
                                            this.state.curso.course_data.lessons?.map(
                                                (lesson: any, index: number) => (
                                                    lesson.percent_complete > 0 ?
                                                        <div key={index}>
                                                            <img src={require('../../Assets/icons/crossbar-black.png')} />
                                                        </div>
                                                        :
                                                        null
                                                )
                                            )
                                        }
                                        <div className='plane-icon'>
                                            <img src={require('../../Assets/icons/plane-black.png')} />
                                        </div>
                                        {
                                            this.state.curso.course_data.lessons?.map(
                                                (lesson: any, index: number) => (
                                                    lesson.percent_complete < 100 ?
                                                        <div key={index}>
                                                            <img src={require('../../Assets/icons/crossbar-pink.png')} />
                                                        </div>
                                                        :
                                                        null
                                                )
                                            )
                                        }
                                    </div>
                                    <div className='lecture-list'>
                                        {
                                            this.state.curso.course_data.lessons?.map(
                                                (lesson: any) => (
                                                    <div className='lecture-item' key={lesson.id}>
                                                        <div className="lecture-icon">
                                                            {
                                                                lesson.media_file !== null ?
                                                                    <img src={"" + lesson.media_file} />
                                                                    :
                                                                    <img src={require('../../Assets/images/home2.png')} />
                                                            }
                                                        </div>
                                                        <div className='lecture-title'>{lesson.title.substring(0, 20) + "..."}</div>
                                                        <div className='lecture-description'>{lesson.body.substring(0, 50) + "..."}</div>
                                                        <LinearWithValueLabel progressValue={lesson.percent_complete} />
                                                    </div>
                                                )
                                            )
                                        }
                                        {
                                            this.state.curso.step == null ?
                                            <div className="button-area end">                                            
                                               <a className='curso-btn btn' onClick={ (e) => this.resetTakenCourse(e) } href={'/course/' + this.state.idCurso + '/lecture'}>REINICIAR CURSO</a>                                               
                                            </div>
                                            :
                                            <div className="button-area end">                                                                                           
                                               <a className='curso-btn btn' href={'/course/' + this.state.idCurso + '/lecture'}>EMPEZAR CURSO</a>
                                            </div>                                            
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        :
                        null
                }
            </Layout>
        );
    }
};
