import React, { useState } from 'react';
import './Assets/flexboxgrid.min.css'
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Cookies from 'js-cookie';
import Login from './pages/login/login';
import { Home } from './pages/home/home';
import HomeCourses from './pages/home-courses/home-courses';
import { CoursePage } from './pages/course/course';
import { Lecture } from './pages/lecture/lecture';
import LectionEnd from './pages/lecture-end/lecture-end';
import './index.css';
import Register from './pages/register/register';


class App extends React.Component<any, any> {

  constructor(props: any) {
    super(props)

    const myCookies: any = Cookies.get("user");
    let parsedCookie = {
      name: '',
      token: '',
      powers: []
    };

    if (typeof myCookies !== 'undefined') {
      parsedCookie = JSON.parse(myCookies)
    }

    this.state = {
      name: parsedCookie.name,
      token: parsedCookie.token,
      powers: parsedCookie.powers
    };
  }
  /*
    IsCookies() {
      const myCookies: any = Cookies.get("user");
  
      if (typeof myCookies !== 'undefined') {
        const parsedCookie = JSON.parse(myCookies);
        console.log(parsedCookie);
        this.setState(parsedCookie);
        return true;
      }
      return false;
    }
  */
  /*function IsCookies() {
  const myCookies: any = Cookies.get("user");

  if (typeof myCookies !== 'undefined') {
    const parsedCookie = JSON.parse(myCookies);
    console.log(parsedCookie);
    setUserCookie(parsedCookie);
  }
  return false;
}
*/
  //if (!IsCookies())
  //return <Login />
  componentDidUpdate(prevprops:any) {
    if(this.state.token !== prevprops.token)
    console.log("PROPS", prevprops);
  }

  componentDidMount() {
    setTimeout(() => {
        this.props.hideLoader();
    }, 1500)    
  }

  render() {
    const token = this.state.token;
    if (this.state.token)
      return (
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<HomeCourses aut={this.state} />} />
            <Route path='/course' element={<CoursePage aut={this.state} />} />
            <Route path='/course/:course/lecture' element={<Lecture aut={this.state} />} />
            <Route path='/course/:course/results/:lesson' element={<LectionEnd aut={this.state} />} />
          </Routes>
        </BrowserRouter>
      );

    else
      return (
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/register' element={<Register state={this}/>} />
          </Routes>
        </BrowserRouter>
      );
  }
}

export default App;
