import { useState } from "react";
import { Modal } from "@mui/material";
import './modalBox.css'

interface Props {
    openModal: boolean,
    onModalClose: (value: boolean) => void,
    lastCourse: any,
}

const ModalBox = (props: Props) => {
    console.log(props.lastCourse);
    return (
        <Modal
            open={props.openModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modal-container">
                <div className="modal-title">Você gostaria  de retomar o seu progresso?</div>
                <div className="modal-description">{props.lastCourse !== undefined? 'Detectamos que você já iniciou um curso: ' + props.lastCourse.title : 'Detectamos que você já iniciou um curso.'}</div>
                <div className="modal-description"> Você gostaria de retomar o seu progresso ou ir para o Home?</div>
                <div className="modal-btnContainer">
                    <button className="modal-confirmBtn" onClick={() => { props.onModalClose(false) }} >Ir para o Home</button>
                    <a className="modal-cancelBtn" href={props.lastCourse !== undefined? '/course/' + props.lastCourse.id + '/lecture' : '/'}>Ir para o Curso</a>
                </div>
            </div>
        </Modal>
    );
};

export default ModalBox;