import { useEffect, useState } from 'react';
import './modalAnswer.css';
import { Close, GppMaybe, Check } from '@mui/icons-material';
import { Button } from '@mui/material';
import internal from 'stream';

interface Props {
    modalMode: string,
    showModal: boolean,
    onModalClose: () => void,
    tries : number
    //nextQuestionMode: string,
}

const ModalAnswer = (props: Props) => {
    //console.log( props.modalMode );
    return (        
        <>            
            {
                
                //props.nextQuestionMode == 'next' ?
                    props.showModal ?
                        props.modalMode == 'correct' ?
                            <div className='modalWrapper'>
                                <div className='modalGoodAnswer'>
                                    <Check
                                        sx={{ color: 'white', height: 30, width: 30 }}
                                        className='modalAnswer-icon'
                                    />
                                    <div className='modalAnswer-textContainer'>
                                        <div className='modalAnswer-title'>Reposta</div>
                                        <div className='modalAnswer-response'>Resposta certa, muito bem!
                                        </div>
                                    </div>
                                    <Button
                                        startIcon={<Close sx={{ height: 20, width: 20, }} />}
                                        style={{ color: 'white', backgroundColor: 'transparent', height: 45, width: 45, }}
                                        onClick={props.onModalClose}
                                    >
                                    </Button>
                                </div>
                            </div>
                        : props.modalMode == 'incorrect' ?
                            <div className='modalWrapper'>
                                <div className='modalBadAnswer'>
                                    <GppMaybe
                                        sx={{ color: 'white', height: 30, width: 30 }}
                                        className='modalAnswer-icon'
                                    />
                                    <div className='modalAnswer-textContainer'>                                    
                                        <div className='modalAnswer-title'>Reposta</div>
                                        <div className='modalAnswer-response'>Resposta errada, tente novamente. Tem mais { 4 - props.tries  } oportunidades</div>
                                    </div>
                                    <Button
                                        startIcon={<Close sx={{ height: 20, width: 20, }} />}
                                        style={{ color: 'white', backgroundColor: 'transparent', height: 45, width: 45, }}
                                        onClick={props.onModalClose}
                                    >
                                    </Button>
                                </div>
                            </div>
                        : props.modalMode == 'next' ?
                            <div className='modalWrapper'>
                                <div className='modalBadAnswer orange'>
                                    <GppMaybe
                                        sx={{ color: 'white', height: 30, width: 30 }}
                                        className='modalAnswer-icon'
                                    />
                                    <div className='modalAnswer-textContainer'>
                                        <div className='modalAnswer-title'>Reposta</div>
                                        <div className='modalAnswer-response'>Resposta errada, você não tem mais tentativas.</div>
                                    </div>
                                    <Button
                                        startIcon={<Close sx={{ height: 20, width: 20, }} />}
                                        style={{ color: 'white', backgroundColor: 'transparent', height: 45, width: 45, }}
                                        onClick={props.onModalClose}
                                    >
                                    </Button>
                                </div>
                            </div>
                        : null
                    :
                    null
            }
            {
                  // linea 61: aqui va el modal de se te acabaron los intentos
            }
        </>
    );
};


export default ModalAnswer;
