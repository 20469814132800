//import '../lection.css';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FaleAudio from "../../media/audio";
import { Answer } from '../../../Utils/Types';


const AnswerDraggable = (props: Answer) => {

    return (
        <Draggable draggableId={'draggable-' + props.answerId} index={props.index}>
            {
                (provided) => (
                    <div className='lection-option' ref={provided.innerRef} {...provided.draggableProps}{...provided.dragHandleProps}>
                        {
                            props.answerText.split(".")[props.answerText.split(".").length - 1] === "mp3" || props.answerText.split(".")[props.answerText.split(".").length - 1] === "ogg" ?
                                <FaleAudio source={ props.answerText }/>                               
                                :
                                <div className='lection-optionText'>{props.answerText}</div>
                        }
                    </div>
                )
            }
        </Draggable>
    );
}

export default AnswerDraggable;