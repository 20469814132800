import { LOGIN_URL } from "./urlManager";
//import Cookies from 'js-cookie';
//import axios from 'axios';


export const login = (body: any) => {
    const response = fetch(LOGIN_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        mode: 'cors'
    })
        .then(response => response.json()
        ).then(
            function (result) {
                console.log("RESPONSE", result);
                if (result.errors.length > 0) {
                    return result.errors[0].msg;
                }
                return result.body;
            }
        ).catch(function (e) {
            console.log("ERROR", e);
        }
        )

    return response;
}

/*

axios({
        method: 'post',
        url: LOGIN_URL,
        data: body
    })
        .then(function (response) {
            console.log(response);
        }).catch(function (e) {
            console.log(e);
        }
        )

*/
