interface Props {
    media_type: any,
    media_file: any
}

const MediaFile = (props: Props) => {

    let mediaFile: any;
    if (props.media_file !== null)
        switch (props.media_type) {
            case 'image':
                mediaFile = <img src={"" + props.media_file} />
                break;
            case 'video':
                mediaFile = <video width='100%' controls>
                    <source src={"" + props.media_file} type={"video/mp4"} />
                </video>
                break;
            case 'audio':
                mediaFile = <audio controls>
                    <source src={"" + props.media_file} type={"audio/mpeg"} />
                </audio>
                break;
        }

    return (
        <div className="image-question">
            {
                mediaFile
            }
        </div>
    );
}

export default MediaFile;