import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Container, Grid, Link, Modal, TextField, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { login } from '../../services/loginService';
//import './login.css';
import Cookies from 'js-cookie';
import { userProfile } from '../../services/userService';

const Login = () => {

  //const { handleLogin, token, hostname, isLoged } = useContext(AdminContext);
  const [enableLoginButton, setEnableLoginButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      user: '',
      password: ''
    },
    validationSchema: Yup.object({
      user: Yup
        .string()
        .email(
          'Must be a valid email')
        .max(255)
        .required(
          'Email is required'),
      password: Yup
        .string()
        .max(255)
        .required(
          'Password is required')
    }),
    onSubmit: (values: any) => {
      Submit(values);
    }
  });

  const Submit = async (values: any) => {
    console.log(values);
    const response = await login(values);
    if (typeof response == 'string') {
      setErrorMessage(response);
      setIsError(true);
      formik.setFieldValue('password', '');
    } else {
      setIsError(false);
      console.log(response);
      const profile = await userProfile(response.token);
      if (profile) {
        const userInfo = { name: profile, ...response }
        Cookies.set('user', JSON.stringify(userInfo));
        window.location.reload();
      }
    }
  }

  return loading ? null : (
    <div className='wrapper login-wrapper'>
          <div className='Login'>
            <Container maxWidth="sm" className='LoginContainer'>
              <div className='LoginContainer-header'>
                <div className='LoginContainer-title'>
                  ¡Bienvenido de vuelta!
                </div>
                <div className="login-icon">
                  <img
                    className='icon'
                    src={require('../../Assets/icons/f.png')}
                  />
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  error={Boolean(formik.touched.user && formik.errors.user)}
                  fullWidth
                  helperText={formik.touched.user && formik.errors.user}
                  label="Email"
                  margin="normal"
                  name="user"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.user}
                  variant="outlined"
                  onClick={() => { setEnableLoginButton(true); }}
                />
                <TextField
                  error={Boolean(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="contraseña"
                  margin="normal"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                  variant="outlined"
                  onClick={() => { setEnableLoginButton(true); }}
                />
                <input
                  className='LoginContainer-button'
                  disabled={!enableLoginButton}
                  type="submit"
                  value='Iniciar Sesión'
                />
              </form>
              <div className='LoginContainer-footerText'>
                Si olvidaste tu contraseña, escribe un correo a <a className="to-mailto" href="mailto:soporte@falemaispt.com" title="Soporte Falemais">soporte@falemaispt.com</a>
              </div>
              <a className='LoginContainer-footerLink' href='/register'>
                ¿No tienes cuenta? ~ Regístrate Aquí
              </a>
            </Container>
            {
              isError ? <Alert severity="error" className='error-alert'>{errorMessage}</Alert> : null
            }
          </div>
    </div>
  );
};

export default Login;
